import React, { useCallback, useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Svg,
  Line,
} from "@react-pdf/renderer";
import sign from "./doctorsign.png";
import TiroDevanagariMarathi from "./font/TiroDevanagariMarathi-Regular.ttf";
Font.register({
  family: "TiroDevanagariMarathi",
  src: TiroDevanagariMarathi,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  tokenNo: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "4.6cm",
    marginLeft: "15cm",
  },
  patientName: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "4cm",
    marginLeft: "2.5cm",
  },
  appointmentDate: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "4cm",
    marginLeft: "15cm",
  },
  patientCity: {
    fontFamily: "Helvetica-BoldOblique",
    position: "absolute",
    fontSize: "11pt",
    marginTop: "5.2cm",
    marginLeft: "2.5cm",
  },
  patientAge: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "4.6cm",
    marginLeft: "2.5cm",
  },
  patientGender: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "8cm",
    marginLeft: "11cm",
  },
  patientWeight: {
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "5.2cm",
    marginLeft: "15cm",
  },
  examination: {
    marginTop: "7.6cm",
    marginLeft: "2.5cm",
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "11pt",
  },
  bp: {
    marginTop: "7.6cm",
    marginLeft: "5.8cm",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "10pt",
  },
  bloodSugar1: {
    marginTop: "7.6cm",
    marginLeft: "6cm",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "11pt",
  },
  bloodSugar2: {
    marginTop: "7.6cm",
    marginLeft: "9.3cm",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "10pt",
  },
  spo2: {
    marginTop: "7.6cm",
    marginLeft: "14cm",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "10pt",
  },
  pulse: {
    marginTop: "7.6cm",
    marginLeft: "17cm",
    fontFamily: "Helvetica",
    position: "absolute",
    fontSize: "10pt",
  },
  present: {
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "11pt",
    marginTop: "6.2cm",
    marginLeft: "2.5cm",
  },
  past: {
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "11pt",
    marginTop: "6.9cm",
    marginLeft: "2.5cm",
  },
  diagnosis: {
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "11pt",
    marginTop: "8.3cm",
    marginLeft: "2.5cm",
  },
  rx: {
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    fontSize: "15pt",
    marginTop: "9.7cm",
    marginLeft: "2.5cm",
  },
  medicineTable1: {
    marginTop: "6.5cm",
    marginLeft: "2.5cm",
  },
  medicineTable2: {
    marginTop: "10.5cm",
    marginLeft: "2.5cm",
  },
  table: {
    display: "table",
    width: "17.2cm",
  },
  tableHead: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11pt",
  },
  tableCell: {
    // margin: "auto",
    marginLeft: 2,
    marginTop: 5,
    fontSize: "11pt",
  },
  tableCell1: {
    marginLeft: 2,
    marginTop: 5,
    fontSize: "10pt",
  },
  tableRow: {
    flexDirection: "row",
    margin: "auto",
  },
  tableColType: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColName: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColDose: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColInstruction: {
    width: "35%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableColQty: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  instruction: {
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "11pt",
  },
  comment: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "500",
    marginLeft: "50",
  },
  nextVisit: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11pt",
    position: "absolute",
    marginTop: "23.3cm",
    marginLeft: "2.5cm",
  },
  nextVisit1: {
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "11pt",
    marginTop: "24cm",
    marginLeft: "2.5cm",
  },
  sign: {
    marginTop: "22.5cm",
    marginLeft: "15cm",
    height: 40,
    position: "absolute",
    width: 80,
  },
  doctorsign: {
    marginLeft: "14cm",
    fontSize: "10pt",
    position: "relative",
    marginTop: "-0.5cm",
  },
  footer: {
    position: "absolute",
  },
  doctorDesc: {
    marginTop: "1.6cm",
    marginLeft: "5.9cm",
    fontFamily: "TiroDevanagariMarathi",
    fontSize: "10pt",
  },
  line: { marginTop: "5.7cm", position: "absolute" },
  line2: { marginTop: "9cm", position: "absolute" },
});

const PrescriptionData = (props) => {
  const [treatmentData, getTreatmentData] = useState([]);
  const [patientDetail, getPatientDetail] = useState([]);
  const [treatmentDetail, setTreatmentDetail] = useState({});
  let tokenNo = props.tokenNo;
  let visitNo = props.visitNo;
  const fetchTreatmentHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patientTreatment/PatientTreatments/" +
          tokenNo +
          "/" +
          visitNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      const transformedTreatment = data.prescription.map((Treatment) => {
        return {
          medicineType: Treatment.medicineType,
          medicineName: Treatment.medicineName,
          qty: Treatment.qty,
          morning: Treatment.morning,
          afternoon: Treatment.afternoon,
          evening: Treatment.evening,
          night: Treatment.night,
          instruction: Treatment.instruction,
          doseTapering: Treatment.doseTapering,
        };
      });
      setTreatmentDetail(data);
      getTreatmentData(transformedTreatment);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [tokenNo, visitNo]);

  useEffect(() => {
    fetchTreatmentHandler();
  }, [fetchTreatmentHandler]);

  const fetchPatientDetails = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/patient/patientByTokenNoAndVisitNo/" +
          tokenNo +
          "/" +
          visitNo,
        {
          headers: {
            "Auth-Token": localStorage.getItem("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      getPatientDetail(data);
    } catch (error) {
      if (error.message === "401") {
        alert("You have been logged out !");
        localStorage.clear();
        window.location.href = "/";
      } else {
        alert("Something went wrong");
      }
    }
  }, [tokenNo, visitNo]);

  useEffect(() => {
    fetchPatientDetails();
  }, [fetchPatientDetails]);

  const medicineTableRows = treatmentData.map((element, index) => {
    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableColType}>
          <Text style={styles.tableCell}>{element.medicineType}</Text>
        </View>
        <View style={styles.tableColName}>
          <Text style={styles.tableCell}>{element.medicineName}</Text>
        </View>
        <View style={styles.tableColDose}>
          <Text style={styles.tableCell}>
            <Text key={element.id}>
              {element.morning === ""
                ? ""
                : element.morning === "0"
                ? ""
                : element.morning + "--"}
            </Text>
            <Text key={element.id}>
              {element.afternoon === ""
                ? ""
                : element.afternoon === "0"
                ? ""
                : element.morning === "" && element.night === ""
                ? "--" + element.afternoon + "--"
                : element.morning === ""
                ? "--" + element.afternoon
                : element.night === ""
                ? element.afternoon + "--"
                : element.afternoon}
            </Text>
            <Text>
              {element.evening === ""
                ? ""
                : element.evening === "0"
                ? ""
                : "--" + element.evening}
            </Text>
            <Text key={element.id}>
              {element.night === ""
                ? ""
                : element.night === "0"
                ? ""
                : element.morning === "" && element.afternoon === ""
                ? "-------" + element.night
                : element.afternoon === ""
                ? "----" + element.night
                : "--" + element.night}
            </Text>
            <Text key={element.id} style={styles.instruction}>
              {element.doseTapering === "None"
                ? ""
                : "\n" + element.doseTapering}
            </Text>
          </Text>
        </View>
        <View style={styles.tableColInstruction}>
          <Text style={[styles.tableCell, styles.instruction]}>
            {element.instruction === "None" ? "" : element.instruction}
          </Text>
        </View>
        <View style={styles.tableColQty}>
          <Text style={styles.tableCell}>{element.qty}</Text>
        </View>
      </View>
    );
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.tokenNo}>
            Token No :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>{tokenNo}</Text>
          </Text>
          <Text style={styles.patientName}>
            Patient Name :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {patientDetail.patientName}
            </Text>
          </Text>
          <Text style={styles.appointmentDate}>
            Date :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {treatmentDetail.treatmentDate}
            </Text>
          </Text>
          <Text style={styles.patientCity}>
            City :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {patientDetail.city}
            </Text>
          </Text>
          <Text style={styles.patientAge}>
            Age/Sex :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {patientDetail.age +
                " " +
                patientDetail.ageUnit +
                "/" +
                patientDetail.gender}
            </Text>
          </Text>
          {/* <Text style={styles.patientGender}>
            Gender :{" "}
            <Text style={{ fontFamily: "Helvetica" }}>
              {patientDetail.gender}
            </Text>
          </Text> */}
          <Text style={styles.patientWeight}>
            {patientDetail.weight !== null
              ? "Weight : " + patientDetail.weight + " kg"
              : ""}
          </Text>
          <Svg style={styles.line} height="5" width="700">
            <Line
              x1="60"
              y1="5"
              x2="560"
              y2="5"
              strokeWidth={2}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          {/* {treatmentDetail.bp !== "" || treatmentDetail.bloodSugar !== "" ? ( */}
          <Text style={styles.examination}>On Examination: </Text>
          {/* ) : (
            ""
          )} */}
          {/* {treatmentDetail.bp !== "" ? ( */}
          <Text style={styles.bp}>
            BP: {treatmentDetail.bp !== "" ? treatmentDetail.bp + " mmHg" : ""}{" "}
          </Text>
          {/* ) : (
            ""
          )} */}
          {/* {treatmentDetail.bloodSugar !== "" ? ( */}
          <Text
            style={
              // treatmentDetail.bp === ""
              //   ? styles.bloodSugar1:
              styles.bloodSugar2
            }
          >
            Blood Sugar:{" "}
            {treatmentDetail.bloodSugar !== ""
              ? treatmentDetail.bloodSugar + " mg/dL"
              : ""}
          </Text>
          <Text style={styles.spo2}>
            SPO2:{" "}
            {/* {treatmentDetail.bloodSugar !== ""
              ? treatmentDetail.bloodSugar + "%"
              : ""} */}
          </Text>
          <Text style={styles.pulse}>
            Pulse:{" "}
            {/* {treatmentDetail.bloodSugar !== ""
              ? treatmentDetail.bloodSugar + "/MIN"
              : ""} */}
          </Text>
          {/* ) : (
            ""
          )} */}
          {/* {treatmentDetail.diagnosis !== undefined
              ? treatmentDetail.diagnosis.length !== 0
                ? */}
          {/* : ""
              : ""} */}
          <Text style={styles.present}>
            Present Complaint: {treatmentDetail.comment}
          </Text>{" "}
          <Text style={styles.past}>
            Past History: 
          </Text>
          <Text style={styles.diagnosis}>
            Diagnosis: {treatmentDetail.diagnosis}
          </Text>
          <Svg style={styles.line2} height="5" width="700">
            <Line
              x1="60"
              y1="5"
              x2="560"
              y2="5"
              strokeWidth={2}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <Text style={styles.rx}>Rx</Text>
          <View style={[styles.table, styles.medicineTable2]}>
            <View style={[styles.tableRow, styles.tableHead]}>
              <View style={styles.tableColType}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableColName}>
                <Text style={styles.tableCell}>Medication</Text>
              </View>
              <View style={styles.tableColDose}>
                <Text style={styles.tableCell}>Dose</Text>
              </View>
              <View style={styles.tableColInstruction}>
                <Text style={styles.tableCell}>Instruction</Text>
              </View>
              <View style={styles.tableColQty}>
                <Text style={styles.tableCell}>Qty</Text>
              </View>
            </View>
            {medicineTableRows}
          </View>
          <View style={styles.footer}>
            <Text style={styles.nextVisit1}>
              {treatmentDetail.nextVisit === "Not Required"
                ? ""
                : "फेर तपासणी तारीख"}
            </Text>
            <Text style={styles.nextVisit}>
              {treatmentDetail.nextVisit === "Not Required"
                ? ""
                : "Next Visit : " + treatmentDetail.nextVisit}
            </Text>
            <Image src={sign} style={styles.sign} />
            <Text style={styles.doctorsign}>Dr. Tarun Kumar Tapan Mandal</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default PrescriptionData;
